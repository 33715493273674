import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../global.css'
import favicon from '../favicon.png'
import Nav from '../navigation.js'
import Footer from '../footer.js'
import privacyPolicyBg from '../privacyPolicyBg.jpg'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${privacyPolicyBg});
  background-size: cover;
  background-position: bottom;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2.4em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
      width: 90%;
      display: inline-block;
      margin-top: 10px;
      font-size: 1.2em;
      @media (max-width: 800px) {
        font-size: 1.1em;
        width: 80%;
      }
    }
  }
`

const Content = styled.section`
  text-align: left;
  font-size: 1em;
  padding: 40px 0;
  h2, h3 {
    text-align: center;
  }
  p {
    width: 80%;
    max-width: 790px;
    margin: 0 auto;
    padding: 15px 30px;
    font-family: nunitoReg, sans-serif;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`

export default () => (
    <Container>
        <Helmet>
            <title>Splendo</title>
            <meta charSet="utf-8" />
            <meta name="description" content="Splendo" />
            <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        </Helmet>

        <Nav />

        <Hero>
            <div className="wrapper">
                <h1>Privacy Policy</h1>
                <span>Terms last modified: June 28th 2021</span>
            </div>
        </Hero>

        <Content>
            <p>The customer agreeing to these terms (“Customer”), and SPLENDO CONSULTING B.V.</p>
            <p>(as applicable, “SPLENDO”) have entered into an agreement under which SPLENDO has agreed to provide the “SPLENDO” products or services to Customer (as amended from time to time, the “Agreement”).</p>
            <p>These SPLENDO Data Processing and Security Terms, including their appendices, (the “Terms”) will be effective and replace any previously applicable data processing and security terms as from the Terms Effective Date (as defined below). These Terms supplement the Agreement.</p>
            <h3>Introduction</h3>
            <p>These Terms reflect the parties agreement with respect to the terms governing the processing and security of Customer Personal Data under the Agreement.</p>
            <h3>Definitions</h3>
            <p>2.1 Capitalized terms used but not defined in these Terms have the meanings set out in the Agreement. In these Terms, unless stated otherwise:</p>
            <p>Account has the meaning given in the Agreement or, if no such meaning is given, means Customer’s account for the Services.</p>
            <p>Additional Product means a product, service or application provided by SPLENDO or a third party that: (a) is not part of the Services; and (b) is accessible for use within the user interface of the Services or is otherwise integrated with the Services.</p>
            <p>Affiliate has the meaning given in the Agreement or, if not such meaning is given, means any entity that directly or indirectly controls, is controlled by, or is under common control with, a party.</p>
            <p>Alternative Transfer Solution means a solution, other than Privacy Shield, that enables the lawful transfer of personal data to a third country in accordance with Article 45 or 46 of the GDPR.</p>
            <p>Customer Personal Data means the personal data provided by or on behalf of Customer.</p>
            <p>Data Incident means a breach of SPLENDO’s security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Customer Personal Data on systems managed by or otherwise controlled by SPLENDO. “Data Incidents” will not include unsuccessful attempts or activities that do not compromise the security of Customer Personal Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems. Data Protection Legislation means: the GDPR.</p>
            <p>EEA means the European Economic Area.</p>
            <p>GDPR means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.</p>
            <p>Notification Email Address means the email address(es) designated by Customer.</p>
            <p>Privacy Shield means the EU-U.S. Privacy Shield legal framework.</p>
            <p>Term means the period from the Terms Effective Date until the end of SPLENDO’s provision of the Services, including, if applicable, any period during which provision of the Services may be suspended and any post-termination period during which SPLENDO may continue providing the Services for transitional purposes.</p>
            <p>Terms Effective Date means, as applicable:<br />
                August 17, 2020, if the Customer agreed to these Terms prior to or on such date; or<br />
                the date on which Customer agreed to these Terms, if such date is after August 17, 2020.</p>
            <p>2.2 The terms “personal data”, “data subject”, “processing”, “controller”, “processor” and “supervisory authority” as used in these Terms have the meanings given in the GDPR.</p><h3>Duration of these Terms</h3><p>These Terms will take effect on the Terms Effective Date and, notwithstanding expiry of the Term, will remain in effect until, and automatically expire upon, deletion of all Customer Personal Data by SPLENDO as described in these Terms.</p>
            <p>Scope of Data Protection Legislation<p>4.1 Application of European Legislation. These Terms will only apply to the extent that the Data Protection Legislation applies to the processing of Customer Personal Data, including if:</p>
                <p>the processing is carried out in the context of the activities of an establishment of Customer in the territory of the EEA; and/or the Customer Personal Data is personal data relating to data subjects who are in the EEA and the processing relates to the offering to them of goods or services in the EEA or the monitoring of their behavior in the EEA.</p>
                <h2>Processing of Data</h2>
                <h3>5.1 Roles and Regulatory Compliance; Authorization.</h3>
                <p>5.1.1 Processor and Controller Responsibilities. The parties acknowledge and agree that:</p>
                <p>the subject matter and details of the processing are described in Appendix 1;<br />
                    SPLENDO is a processor of that Customer Personal Data under the Data Protection Legislation;<br />
                    Customer is a controller or processor, as applicable, of that Customer Personal Data under Data Protection Legislation; and<br />
                    each party will comply with the obligations applicable to it under the Data Protection Legislation with respect to the processing of that Customer Personal Data.</p>
                <h2>5.2 Scope of Processing.</h2>
                <p>5.2.1 Customer’s Instructions. By entering into these Terms, Customer instructs SPLENDO to process Customer Personal Data only in accordance with applicable law: to provide the Services;  as further specified via Customer’s use of the Services; as documented in the form of the Agreement, including these Terms; and as further documented in any other written instructions given by Customer and acknowledged by SPLENDO as constituting instructions for purposes of these Terms.</p>
                <p>5.2.2 SPLENDO’s Compliance with Instructions. SPLENDO will comply with the instructions described in Section 5.2.1 (Customer’s Instructions) (including with regard to data transfers) unless EU or EU Member State law to which SPLENDO is subject requires other processing of Customer Personal Data by SPLENDO, in which case SPLENDO will inform Customer (unless that law prohibits SPLENDO from doing so on important grounds of public interest) via the Notification Email Address.</p>
                <h3>5.3 Additional Products.</h3>If Customer uses an Additional Product, the Services may allow that Additional Product to access Customer Personal Data as required for the interoperation of the Additional Product with the Services. For clarity, these Terms do not apply to the processing of personal data in connection with the provision of any Additional Product used by Customer, including personal data transmitted to or from that Additional Product.</p>
            <h2>Data Deletion</h2>
            <h3>6.1 Deletion by Customer.</h3>
            <p>SPLENDO will enable Customer to delete Customer Personal Data during the Term in a manner consistent with the functionality of the Services. If Customer uses the Services to delete any Customer Personal Data during the Term and that Customer Personal Data cannot be recovered by Customer, this use will constitute an instruction to SPLENDO to delete the relevant Customer Personal Data from SPLENDO’s systems in accordance with applicable law. SPLENDO will comply with this instruction as soon as reasonably practicable and within a maximum period of 180 days, unless EU or EU Member State law requires storage.</p>
            <h3>6.2 Deletion on Termination.</h3>
            <p>On expiry of the Term, Customer instructs SPLENDO to delete all Customer Personal Data (including existing copies) from SPLENDO’s systems in accordance with applicable law. SPLENDO will, after a recovery period of up to 30 days following such expiry, comply with this instruction as soon as reasonably practicable and within a maximum period of 180 days, unless EU or EU Member State law requires storage. Without prejudice to Section 11.1 (Access; Rectification; Restricted Processing; Portability), Customer acknowledges and agrees that Customer will be responsible for exporting, before the Term expires, any Customer Personal Data it wishes to retain afterwards.</p>
            <h2>Data Security</h2>
            <h3>7.1 SPLENDO’s Security Measures, Controls and Assistance.</h3>
            <p>7.1.1 SPLENDO’s Security Measures. SPLENDO will implement and maintain technical and organizational measures to protect Customer Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access. The Security Measures include measures to help ensure ongoing confidentiality, integrity, availability and resilience of SPLENDO’s systems and services; to help restore timely access to personal data following an incident; and for regular testing of effectiveness. SPLENDO may update or modify the Security Measures from time to time provided that such updates and modifications do not result in the degradation of the overall security of the Services.</p>
            <p>7.1.2 Security Compliance by SPLENDO Staff. SPLENDO will take appropriate steps to ensure compliance with the Security Measures by its employees, contractors and Subprocessors to the extent applicable to their scope of performance, including ensuring that all persons authorized to process Customer Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.</p>
            <h3>7.2.Data Incidents</h3>
            <p>7.2.1 Incident Notification. If SPLENDO becomes aware of a Data Incident, SPLENDO will: (a) notify Customer of the Data Incident promptly and without undue delay after becoming aware of the Data Incident; and (b) promptly take reasonable steps to minimize harm and secure Customer Personal Data.</p>
            <p>7.2.2 Details of Data Incident. Notifications made pursuant to this section will describe, to the extent possible, details of the Data Incident, including steps taken to mitigate the potential risks and steps SPLENDO recommends Customer take to address the Data Incident.</p>
            <p>7.2.3 Delivery of Notification. Notification(s) of any Data Incident(s) will be delivered to the Notification Email Address or, at SPLENDO’s discretion, by direct communication (for example, by phone call or an in-person meeting). Customer is solely responsible for ensuring that the Notification Email Address is current and valid.</p>
            <p>7.2.4 No Assessment of Customer Personal Data by SPLENDO. SPLENDO will not assess the contents of Customer Personal Data to identify information subject to any specific legal requirements. Customer is solely responsible for complying with incident notification laws applicable to Customer and fulfilling any third party notification obligations related to any Data Incident(s).</p>
            <p>7.2.5 No Acknowledgement of Fault by SPLENDO. SPLENDO’s notification of or response to a Data Incident under this Section 7.2 (Data Incidents) will not be construed as an acknowledgement by SPLENDO of any fault or liability with respect to the Data Incident.</p>
            <h3>7.3 Customer’s Security Responsibilities and Assessment.</h3>
            <p>7.3.1 Customer’s Security Responsibilities. Customer agrees that, without prejudice to SPLENDO’s obligations under Section 7.1 (SPLENDO’s Security Measures, Controls and Assistance) and Section 7.2 (Data Incidents):</p>
            <p>Customer is solely responsible for its use of the Services, including: making appropriate use of the Services to ensure a level of security appropriate to the risk in respect of the Customer Personal Data; securing the account authentication credentials, systems and devices Customer uses to access the Services; backing up its Customer Personal Data; and SPLENDO has no obligation to protect Customer Personal Data that Customer elects to store or transfer outside of SPLENDO’s and its Subprocessors’ systems (for example, offline or on-premise storage), or to protect Customer Personal Data by implementing or maintaining additional security controls except to the extent Customer has opted to use them. 7.3.2 Customer’s Security Assessment.</p>
            <p>Customer is solely responsible for reviewing the Security Documentation and evaluating for itself whether the Services, the Security Measures, the Additional Security Controls and SPLENDO’s commitments under this Section 7 (Data Security) will meet Customer’s needs, including with respect to any security obligations of Customer under the Data Protection Legislation. Customer acknowledges and agrees that (taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of Customer Personal Data as well as the risks to individuals) the Security Measures implemented and maintained by SPLENDO as set out in Section 7.1.1 (SPLENDO’s Security Measures) provide a level of security appropriate to the risk in respect of the Customer Personal Data. Data storage and processing locations SPLENDO may process and store your data in Europe and the USA.</p>
            <h2>Security information</h2>
            <h3>9.1 Data encryption.</h3>
            <p>SPLENDO services encrypt data in transit using HTTPS and logically isolate customer data. In addition, several SPLENDO services may also encrypt their data at rest.</p>
            <p>Redundancy: Infrastructure systems have been designed to eliminate single points of failure and minimize the impact of anticipated environmental risks. Dual circuits, switches, networks or other necessary devices help provide this redundancy.</p>
            <h2>9.3 Security practices.</h2>
            <p>To keep personal data safe, SPLENDO employs extensive security measures to minimize access:</p>
            <p>SPLENDO restricts access to a select employees who have a business purpose to access personal data. SPLENDO employees are required to conduct themselves in a manner consistent with the company’s guidelines regarding confidentiality, business ethics, appropriate usage, and professional standards.</p>
            <h3>Impact Assessments and Consultations</h3>
            <p>Customer agrees that SPLENDO will (taking into account the nature of the processing and the information available to SPLENDO) assist Customer in ensuring compliance with any obligations of Customer in respect of data protection impact assessments and prior consultation, including if applicable Customer’s obligations pursuant to Articles 35 and 36 of the GDPR, by providing the information contained in the Agreement including these Terms.</p>
            <h3>Data Subject Rights; Data Export</h3>
            <h3>11.1 Access; Rectification;</h3>
            <p>Restricted Processing; Portability. During the Term, SPLENDO will, in a manner consistent with the functionality of the Services, enable Customer to access, rectify and restrict processing of Customer Personal Data, including via the deletion functionality provided by SPLENDO as described in Section 6.1 (Deletion by Customer), and to export Customer Personal Data.</p>
            <h3>11.2 Data Subject Requests</h3>
            <p>11.2.1 Customer’s Responsibility for Requests. During the Term, if SPLENDO receives any request from a data subject in relation to Customer Personal Data, SPLENDO will advise the data subject to submit their request to Customer and Customer will be responsible for responding to any such request including, where necessary, by using the functionality of the Services.</p>
            <p>11.2.2 SPLENDO’s Data Subject Request Assistance. Customer agrees that SPLENDO will (taking into account the nature of the processing of Customer Personal Data) assist Customer in fulfilling any obligation to respond to requests by data subjects, including if applicable Customer’s obligation to respond to requests for exercising the data subject’s rights laid down in Chapter III of the GDPR, by complying with the commitments set out in Section 11.1 (Access; Rectification; Restricted Processing; Portability) and Section 11.2.1 (Customer’s Responsibility for Requests).</p>
            <h2>SPLENDO Data Protection Team; Processing Records</h2>
            <h3>12.1 SPLENDO Representative.</h3>
            <p>Customer may contact a SPLENDO representative in relation to the exercise of its rights under these Terms via email to info@splendo.com</p>
            <h3>12.2 SPLENDO’s Processing Records.</h3>
            <p>Customer acknowledges that SPLENDO is required under the GDPR to: (a) collect and maintain records of certain information, including the name and contact details of each processor and/or controller on behalf of which SPLENDO is acting and, where applicable, of such processor’s or controller’s local representative and data protection officer; and (b) make such information available to the supervisory authorities. Accordingly Customer will, where requested, provide such information to SPLENDO via the SPLENDOCloudPortal or other means provided by SPLENDO, and will use the SPLENDOCloudPortal or such other means to ensure that all information provided is kept accurate and up-to-date.</p>
            <h2>Liability</h2>
            <p>The jurisdiction of any disputes will be established in The Netherlands. This Agreement shall be governed by and construed in accordance with the laws of The Netherlands.</p>
            <p>Any and all disputes and legal actions arising out of the interpretation or application of this Agreement shall be disputes resolved by and brought exclusively in the courts of The Netherlands. This Agreement shall be interpreted and enforced in accordance with the laws of The Netherlands.</p>
            <p>In the event that any portion of this Agreement is held unenforceable, the unenforceable portion shall be construed in accordance with applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of the provisions shall remain in full force and effect.</p>
            <h3>Legal Disclaimer</h3>
            <p>Though we make every effort to preserve user privacy, we may need to disclose personal information when required by law wherein we have good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order or legal process served on our website.</p>
            <h3>Attorney fees</h3>
            <p>In any litigation, arbitration, or other proceeding by which one party either seeks to enforce its rights under this Agreement (whether in contract, tort, or both), or seeks a declaration of any rights or obligations under this Agreement, the prevailing party shall be awarded its reasonable attorney fees and costs and expenses incurred.</p>
            <h3>Effect of these Terms</h3>
            <p>Notwithstanding anything to the contrary in the Agreement, to the extent of any conflict or inconsistency between these Terms and the remaining terms of the Agreement, these Terms will govern.</p>
            <h2>Appendix 1: Subject Matter and Details of the Data Processing</h2>
            <h3>Subject Matter</h3>
            <p>SPLENDO’s provision of the Services to Customer.</p>
            <h3>Duration of the Processing</h3>
            <p>The Term plus the period from the expiry of the Term until deletion of all Customer Personal Data by SPLENDO in accordance with these Terms. We will retain your personal data as long as your SPLENDO account is considered to be active.</p>
            <h3>Nature and Purpose of the Processing</h3>
            <p>SPLENDO will process Customer Personal Data for the purposes of providing the Services to Customer in accordance with these Terms.</p>
            <h3>Personal data that is processed when you create a SPLENDO account:</h3>
            <p>When you create a SPLENDO account, we ask you to provide your email address and name. You can choose to provide only your first name or a nickname instead of your full name if you wish.</p>
            <p>Purposes and Legal Ground:<br />
                SPLENDO processes your email address because you use your email address and your password to sign in to your account. The legal ground for processing your email address for this purpose is based on SPLENDO’s legitimate interest in protecting the security of your account.</p>
            <p>SPLENDO also processes your email address for the purpose of sending you important information about your SPLENDO products, apps or account, such as important safety information or material changes to this Privacy Policy. The legal ground for processing your email address and name for these purposes is SPLENDO’s legitimate interest in providing you important safety or other information about your SPLENDO products, apps or account or material changes to this Privacy Policy</p>
            <p>If you provide your opt-in consent to receiving marketing information from SPLENDO or one of our partners, SPLENDO will also process your email address for the purpose of sending you marketing information about SPLENDO products and apps. The legal ground for processing your email address for this purpose is your consent. You may withdraw your consent at any time by changing your preferences in your SPLENDO account or through the unsubscribe link at the bottom of our marketing emails.</p>
            <p>Additional personal data that is processed if you choose to provide it when you create a SPLENDO account:</p>
            <p>You can add additional information to your profile, such as your gender, birthdate, height and weight.</p>
            <p>Purposes and Legal Ground:<br />
                This information is used to calculate the calories you burn during an activity and to enable you to show the types of activities you participate in if you wish. The legal ground for processing this information for these purposes is your consent. You can withdraw your consent by deleting this information from your account profile.</p>
            <p>Personal data that is processed when you choose to upload your workouts, activity data to SPLENDO:</p>
            <p>You can choose to upload workouts, activity data (e.g., steps, distance, activity time, calories burned, heart rate, sleep, location, temperature, barometer, altitude, UV index…) from your device to your SPLENDO account.</p>
            <p>Purposes and Legal Ground:<br />
                SPLENDO processes your activity and sleep data, if you choose to upload it to SPLENDO, to enable you to analyze your activity data, see your location on your activity workout and segment maps, see your heart rate related metrics, sleep data, altitude, temperature, barometer, UV index…</p>
            <p>If you choose to upload activity data (such as steps, distance, activity time, calories burned, heart rate, sleep, etc.) from your SPLENDO device to your SPLENDO account and you choose to participate in Insights, then you will be presented with an Insights section in your SPLENDO account in which you will be provided with recommendations and motivational messages, information and links to articles that may be of interest to you based upon your activity data, and a comparison of your activity data with anonymous aggregated activity data of others in the SPLENDO community.</p>
            <p>Personal data that is processed when you sync any sensor with a your SPLENDO device:</p>
            <p>When you sync your device through one of our SPLENDO applications we may upload information concerning the device, such as brand name, manufacturer, serial number, model, battery level, the sync time and date, crash/diagnostic logs.</p>
            <p>Purposes and Legal Ground:<br />
                We process this information to help identify and resolve errors or syncing issues. The legal ground for processing this information for this purpose is SPLENDO’s legitimate interest in resolving errors or syncing issues and providing quality product support.</p>
            <h3>Categories of Data</h3>
            <p>Data relating to individuals provided to SPLENDO via the Services, by (or at the direction of) Customer or by Customer End Users.</p>
            <h3>Data Subjects</h3>
            <p>Data subjects include the individuals about whom data is provided to SPLENDO via the Services by (or at the direction of) Customer or by Customer End Users.</p>
            <h2>Cookies and Similar Technologies</h2>
            <h3>Websites</h3>
            <p>To help analyze how you and other visitors navigate SPLENDO websites, and compile aggregate statistics about site usage and response rates, we, with assistance from third-party analytics service providers, collect certain information when you visit our site. This information may includes IP address, geographic location of the device, browser type, browser language, date and time of your request, time(s) of your visit(s), page views and page elements (e.g. links) that you click. We may use cookies, pixel tags, web beacons, clear GIFs or other similar tools on our site or in our email messages to assist us in collecting and analyzing such information. We use this information to provide better, more relevant content on our site, to measure the effectiveness of advertisements, to identify and fix problems, and to improve your overall experience on our site. If you do not want information collected through the use of these technologies, there is a simple procedure in most browsers that allows you to automatically decline many of these technologies, or to be given the choice of declining or accepting them.</p>
            <p>If you reside in the European Union or other jurisdiction that requires us to obtain your consent to use cookies on our sites, then you will have an opportunity to manage your cookie preferences on the sites; except that certain cookies are required to enable core site functionality, and you cannot choose to disable those cookies.</p>
            <h3>SPLENDO Mobile apps</h3>
            <p>We also collect data from users about their usage of SPLENDO mobile apps. The types of analytical information that are collected may include the date and time the app accesses our servers, app version, the location of the device, language setting, what information and files have been downloaded to the app, user behavior (e.g., features used, frequency of use), device state information, device model, hardware and operating system information, and information relating to how the app functions. SPLENDO uses this data to improve the quality and functionality of our mobile apps to develop and market products and features that best serve you and other users and to help identify and fix app stability issues and other usability problems as quickly as possible.</p>
            <p>The legal ground for processing this analytical information is our legitimate interest in understanding how our customers interact with our products, apps and websites so we can enhance the user experience and functionality of our products, apps and websites.</p>
            <h3>Children</h3>
            <p>We request individuals under 16 not provide personal data to SPLENDO without consent of a parent. If we learn that we have collected the personal data from a child under 16 without consent, we will take steps to delete the information as soon as possible.</p>
            <h3>Data Controller and Data Protection Officer</h3>
            <p>Your personal data collected by SPLENDO is controlled by SPLENDO B.V., which is located at Marineweg 5, 2241 TX Wassenaar, the Netherlands. SPLENDO’s Data Protection Officer is located at the same address and can also be reached by email at privacy@splendo.com.</p>
            <h3>Your Rights</h3>
            <p>If you reside in the EEA, you have the right under the General Data Protection Regulation to request from SPLENDO access to and rectification or erasure of your personal data, data portability, restriction of processing of your personal data, the right to object to processing of your personal data, and the right to lodge a complaint with a supervisory authority. If you reside outside of the European Union, you may have similar rights under your local laws.</p>
            <p>To request access to or rectification, portability or erasure of your personal data, or to delete your SPLENDO account, check your application under Settings/Consumer Rights Management.</p>
            <p>If you live in the EEA and you wish to exercise your right to restriction of processing or your right to object to processing, contact SPLENDO Data Protection Officer at SPLENDO HEALTH B.V. Marineweg 5, 2241 TX Wassenaar, the Netherlands or by email at privacy@splendo.com. If you do not live in the EEA but you believe you have a right to restriction of processing or a right to object to processing under your local laws, please contact us using the address or email above.</p>
            <h2>Appendix 2: Security Measures</h2>
            <p>As from the Terms Effective Date, SPLENDO will implement and maintain the Security Measures set out in this Appendix 2. SPLENDO may update or modify such Security Measures from time to time, provided that such updates and modifications do not result in the degradation of the overall security of the Processor Services.</p>
            <h2>Access</h2>
            <h3>Access Control</h3>
            <p>Infrastructure Security Personnel. SPLENDO has, and maintains, a security policy for its personnel, and requires security training as part of the training package for its personnel. SPLENDO’s infrastructure security personnel are responsible for the ongoing monitoring of SPLENDO’s security infrastructure, the review of the Processor Services, and responding to security incidents.</p>
            <p>Internal Data Access Processes and Policies – Access Policy. SPLENDO’s internal data access processes and policies are designed to prevent unauthorised persons and/or systems from gaining access to systems used to process personal data. SPLENDO aims to design its systems to: (i) only allow authorised persons to access data they are authorised to access; and (ii) ensure that personal data cannot be read, copied, altered or removed without authorisation during processing, use and after recording. The systems are designed to detect any inappropriate access. SPLENDO employs a centralised access management system to control personnel access to production servers, and only provides access to a limited number of authorised personnel. LDAP, Kerberos and SSH certificates are designed to provide SPLENDO with secure and flexible access mechanisms. These mechanisms are designed to grant only approved access rights to site hosts, logs, data and configuration information. SPLENDO requires the use of unique user IDs, strong passwords and carefully monitored access lists to minimise the potential for unauthorised account use. The granting or modification of access rights is based on: the authorised personnel’s job responsibilities; job duty requirements necessary to perform authorised tasks; and a need to know basis. The granting or modification of access rights must also be in accordance with SPLENDO’s internal data access policies and training. Where passwords are employed for authentication (e.g. login to workstations), password policies that follow at least industry standard practices are implemented. These standards include restrictions on password reuse and sufficient password strength.</p>
            <h2>Data</h2>
            <h3>Data Storage, Isolation and Authentication.</h3>
            <p>SPLENDO stores data in a multi-tenant environment on SPLENDO Data, the Processor Services database and file system architecture are replicated between multiple geographically dispersed data centres in Europe and the USA. SPLENDO logically isolates each customer’s data. A central authentication system is used across all Processor Services to increase uniform security of data.</p>
            <h3>Decommissioned Disks and Disk Destruction Guidelines.</h3>
            <p>Certain disks containing data may experience performance issues, errors or hardware failure that lead them to be decommissioned (“Decommissioned Disk”). Every Decommissioned Disk is subject to a series of data destruction processes (the “Data Destruction Guidelines”) before leaving SPLENDO’s premises either for reuse or destruction. Decommissioned Disks are erased in a multi-step process and verified complete by at least two independent validators. If, due to hardware failure, the Decommissioned Disk cannot be erased, it is securely stored until it can be destroyed.</p>
            <h3>Personnel Security</h3>
            <p>SPLENDO personnel are required to conduct themselves in a manner consistent with the company’s guidelines regarding confidentiality, business ethics, appropriate usage, and professional standards. SPLENDO conducts reasonably appropriate backgrounds checks to the extent legally permissible and in accordance with applicable local labor law and statutory regulations.</p>
            <p>Personnel are required to execute a confidentiality agreement and must acknowledge receipt of, and compliance with, SPLENDO’s confidentiality and privacy policies. Personnel are provided with security training. Personnel handling Customer Personal Data are required to complete additional requirements appropriate to their role. SPLENDO’s personnel will not process Customer Personal Data without authorisation.</p>
            <h3>Subprocessor Security</h3>
            <p>Before onboarding Subprocessors, SPLENDO conducts an audit of the security and privacy practices of Subprocessors to ensure Subprocessors provide a level of security and privacy appropriate to their access to data and the scope of the services they are engaged to provide. Once SPLENDO has assessed the risks presented by the Subprocessor the Subprocessor is required to enter into appropriate security, confidentiality and privacy contract terms.</p>
        </Content>

        <Footer />
    </Container>
)
